<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{ $t("message.categories") }}
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="flex-style text-right">
            <el-button size="small" class="btu-color" @click="create()">
              {{ $t("message.create") }}
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      <div class="card-table-header table-crm-smart">
        <div class="custom-tree-container">
          <div class="block mm_custom_tree">
            <el-tree
              ref="categoryTree"
              :class="mode ? '' : 'mm__background'"
              :data="treeData"
              node-key="id"
              :props="defaultProps"
              default-expand-all
              draggable
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
              @node-drop="handleDrop"
              @node-drag-start="handleDragStart"
              >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <el-button
                    v-if="data.children && data.children.length == 0 && permissions.some(per => per.slug == 'categories.edit')"
                    class="mm__font__size"
                    type="text"
                    size="mini"
                    icon="el-icon-guide"
                    @click="attributesDrawer(data)">
                  </el-button>
                  <el-button
                    v-if="permissions.some(per => per.slug == 'categories.create')"
                    class="mm__font__size"
                    type="text"
                    size="mini"
                    icon="el-icon-circle-plus-outline"
                    @click="append(data)">
                  </el-button>
                  <el-button
                    v-if="permissions.some(per => per.slug == 'categories.edit')"
                    class="mm__font__size"
                    type="text"
                    size="mini"
                    icon="el-icon-edit-outline"
                    @click="updateCategory(data)">
                  </el-button>
                  <el-button
                    v-if="permissions.some(per => per.slug == 'categories.delete')"
                    class="mm__font__size"
                    type="text"
                    size="mini"
                    icon="el-icon-delete-solid"
                    @click="remove(node, data)">
                  </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <el-dialog :title="selectedMethod === 'store' ? $t('message.add') : $t('message.update')" :visible.sync="drawerCreate">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item
              :label="$t('message.name')"
              class="label_mini"
              prop="name"
            >
              <el-input
                :placeholder="$t('message.name')"
                v-model="form.name"
              ></el-input>
            </el-form-item>

            <el-form-item
              :label="$t('message.calculation_type')"
              class="label_mini"
              prop="calculation_type"
            >
              <SelectFourthCalcTypes
                :placeholder="$t('message.calculation_type')"
                :id="form.calculation_type"
                v-model="form.calculation_type"
              >
              </SelectFourthCalcTypes>
            </el-form-item>

            <el-form-item
              :label="$t('message.documentTemplates')"
              class="label_mini"
              prop="document_templates"
            >
              <SelectDocumentTemplates
                :placeholder="$t('message.documentTemplates')"
                :size="'large'"
                :id="form.document_templates"
                v-model="form.document_templates"
              >
              </SelectDocumentTemplates>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button v-loading="loadingButton" v-if="checkPermissions()" type="primary" @click="checkMethod()">{{ $t('message.save') }}</el-button>
            <el-button @click="drawerCreate = false">{{ $t('message.cancel') }}</el-button>
          </span>
      </el-dialog>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
            :with-header="false"
            :visible.sync="drawerAttribute"
            ref="drawerAttribute"
            size="70%"
            @opened="drawerOpened('drawerAttributeChild')"
            @closed="drawerClosed('drawerAttributeChild')"
        >
            <div>
                <crm-attribute
                    ref="drawerAttributeChild"
                    drawer="drawerAttribute"
                    :category="selectedData"
                >
                </crm-attribute>
            </div>
        </el-drawer>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from 'lodash';
import form from "@/utils/mixins/form";
import child_list from "@/utils/mixins/child_list";
import { mapGetters, mapActions } from "vuex";
import CrmAttribute from "./components/attributes";
import SelectFourthCalcTypes from "@/components/inventory/select-calculation-type";
import SelectDocumentTemplates from "@/components/inventory/select-document-templates";

export default {
  name: "category",
  mixins: [form, child_list],
  components: {
    CrmAttribute,
    SelectFourthCalcTypes,
    SelectDocumentTemplates
  },

  data() {
    return {
      selectedMethod: '', 
      drawerAttribute: false,
      drawerCreate: false,
      loadingButton: false,
      selectedData: {},
      selectedNode: {},
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      treeDataBeforeDrag: [],
      treeData: []
    };
  },

  created() {
      this.updateList().then(res => {
        this.treeData = JSON.parse(JSON.stringify(this.list));
      })
  },
  computed: {
    ...mapGetters({
      permissions: "auth/permissions",
      list: "categories/treeInventory",
      rules: "categories/rules",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      show: "categories/show",
      empty: "categories/empty",
      delete: "categories/destroy",
      refreshData: "categories/refreshData",
      categoryAnalize: "categories/categoryAnalize",
      updateList: "categories/treeInventory",
      save: "categories/store",
      update: "categories/update",
    }),
    attributesDrawer(data) {
      this.selectedData = data;
      this.drawerAttribute = true;
    },
    checkPermissions(){
      if((this.selectedMethod === 'store' || this.selectedMethod === 'new_store') && this.permissions.some(per => per.slug == 'categories.create')) return true;
      if(this.selectedMethod === 'update' && this.permissions.some(per => per.slug == 'categories.update')) return true;
      return false;
    },
    checkMethod(){
      return (this.selectedMethod === 'new_store') ? this.craeteNew() : this.submit_check();
    },
    create(){
      this.selectedMethod = 'new_store';
      this.$set(this.form, 'parent_id', null);
      this.$set(this.form, 'name', '');
      this.$set(this.form, 'calculation_type', '');
      this.$set(this.form, 'document_templates', '');
      this.drawerCreate = true;
    },
    craeteNew(){
      this.$refs["form"].validate((valid) => {
        this.loadingButton = true;  
        if (valid) {
          this.save(this.form).then(res => {
            this.treeData.unshift(res.data.result.data.category);
            this.loadingButton = false;    
            this.drawerCreate = false;
          }).catch(err => {
            this.loadingButton = false;    
            this.drawerCreate = false;
          });
        }
      });
    },
    append(data) {
      this.selectedMethod = 'store';
      this.$set(this.form, 'parent_id', data.id);
      this.$set(this.form, 'name', '');  
      this.$set(this.form, 'calculation_type', '');
      this.$set(this.form, 'document_templates', []);
      this.selectedData = data;
      this.drawerCreate = true;
    },
    updateCategory(data){
      this.selectedMethod = 'update';
      if(data.parent_id){
        this.$set(this.form, 'parent_id', data.parent_id);
      }
      this.$set(this.form, 'id', data.id);
      this.$set(this.form, 'name', data.name);
      this.$set(this.form, 'calculation_type', data.calculation_type);
      this.$set(this.form, 'document_templates', data.document_templates);
      this.selectedData = data;
      this.drawerCreate = true;
    },
    remove(node, data) {
      this.$confirm(
        this.$t('message.do_you_really_want_to_do_this'),
        this.$t('message.warning'), {
          confirmButtonText: this.$t('message.yes'),
          cancelButtonText: this.$t('message.no'),
          type: "warning"
        }
      )
      .then(() => {
        this.selectedNode = node;
        this.selectedData = data;
        this.destroy(data);
      })
      .catch(() => {
        this.$message({
          type: "warning",
          message: this.$t('message.operation_canceled')
        });
      });
    },
    allowDrop(draggingNode) {
      if(draggingNode.data.children && draggingNode.data.children.length > 0) return false;
      return true;
    },
    allowDrag(draggingNode) {
      if(this.permissions.some(per => per.slug == 'categories.edit')) return true;
      if(draggingNode.data.children && draggingNode.data.children.length > 0) return false;
      return false
    },
    handleDragStart(draggingNode, dropNode, dropType, ev){
      this.treeDataBeforeDrag = JSON.parse(JSON.stringify(this.treeData));  
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      this.form.id = draggingNode.data.id;
      this.form.name = draggingNode.data.name;
      this.form.parent_id = null;
      this.selectedMethod = 'update';
      if(dropType === 'before' || dropType === 'after'){
        this.form.parent_id = dropNode.data.parent_id;
      }
      if(dropType === 'inner'){
        this.form.parent_id = dropNode.data.id;
      }
      this.submit_check();
    },
    submit_check(){
      this.categoryAnalize({category_id: this.form.parent_id, analize_type: this.selectedMethod})
        .then(res => {
          if(res.data.result.success){
            if(this.$refs["form"]){
              this.submit(true);
            }else{
              this.updateAll();
            }
          }else{
            this.$confirm(
              res.data.result.message,
              this.$t('message.warning'), {
                confirmButtonText: this.$t('message.yes'),
                cancelButtonText: this.$t('message.no'),
                type: "warning"
              }
            )
            .then(() => {
              if(this.$refs["form"]){
                this.submit(true);
              }else{
                this.updateAll();
              }
            })
            .catch(() => {
              this.treeData = JSON.parse(JSON.stringify(this.treeDataBeforeDrag));
              this.$message({
                type: "warning",
                message: this.$t('message.operation_canceled')
              });
            });
          }

        })
        .catch((err) => {
          this.$alert(err);
        });
    },
    async selectedMethodType(){
      if(this.selectedMethod === 'store'){
        return this.save(this.form);
      }
      if(this.selectedMethod === 'update'){
        return this.update(this.form);
      }
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.updateAll();
        }
      });
    },
    updateAll(){
      this.loadingButton = true;
      this.selectedMethodType()
        .then((res) => {  
          this.loadingButton = false;
          if(res.data.result.success){
            this.updateTree(res.data.result.data);
            this.$alert(res);
            this.drawerCreate = false;
          }else{
            this.$info_message(res);
          }            
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
    },
    updateTree(data){
      if(this.selectedMethod === 'store'){
        const newChild = { 
            id: JSON.parse(JSON.stringify(data.category.id)), 
            name: JSON.parse(JSON.stringify(data.category.name)), 
            calculation_type: JSON.parse(JSON.stringify(data.category.calculation_type)), 
            document_templates: JSON.parse(JSON.stringify(data.category.document_templates)), 
            children: []
        };
        if (! this.selectedData.children) {
          this.$set(this.selectedData, 'children', []);
        }
        this.selectedData.children.push(newChild);
      }
      if(this.selectedMethod === 'update'){
        this.selectedData.name = JSON.parse(JSON.stringify(data.category.name));
        this.selectedData.calculation_type = JSON.parse(JSON.stringify(data.category.calculation_type));
        this.selectedData.document_templates = JSON.parse(JSON.stringify(data.category.document_templates));
      }
    },

    destroy(model) {
      this.categoryAnalize({category_id: model.id, analize_type: 'delete'})
        .then(res => {
          if(res.data.result.success){
            this.delete(model.id)
              .then((res) => {
                const parent = this.selectedNode.parent;
                const children = parent.data.children || parent.data;
                const index = children.findIndex(d => d.id === this.selectedData.id);
                children.splice(index, 1);
                this.$alert(res);
              })
              .catch((err) => {
                this.$alert(err);
              });
          }else{
            this.$notify({
              title: this.$t('message.warning'),
              type: "warning",
              offset: 130,
              message: res.data.result.message
            }); 
          }

        })
        .catch((err) => {
          this.$alert(err);
        });
      
      
    },
  },
  beforeRouteLeave (to, from, next) {
      this.$store.commit('categories/EMPTY_LIST');
      next()
  },
};
</script>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding-right: 8px;
  }
  .mm_custom_tree .is-expanded {
    width: 100%;
  }
  .mm__font__size {
    font-size: 18px;
  }
  .mm__background {
    background-color:  transparent;
    color:  white;
  }
</style>
