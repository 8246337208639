<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.attributes')"
      filterable
      clearable
      :size="size"
      class="w-100"
      :loading="loading"
    >
      <el-option
        v-for="(attr, index) in attributes"
        :key="'attributes-' + index"
        :label="attr.name"
        :value="attr.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    step: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      deep: true,
      immediate: true,
    },
    step: {
        handler: async function(newVal, oldVal) {
          if(newVal && newVal != oldVal){
            await this.updateData(this);
          }
        },
        deep: true,
        immediate: true
    },
  },
  data() {
    return {
      selected: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      attributes: "attributes/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "attributes/inventory",
    }),
    updateData: _.debounce(async function(self) {
      self.loading = true;
      await self.updateInventory({step: self.step})
        .then(res =>{
          self.loading = false;
        })
        .catch(err => {
          self.loading = false;
        })
    }, 500), 
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
      this.findAndEmitItem(e);
    },
    findAndEmitItem(id){
      let item = this.attributes.find(el => el.id == id);
      if(item && item.id){
        this.$emit("getItem", item);
      }
    }
  },
};
</script>
