<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{ $t("message.category_attributes") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-tabs v-model="activeTabName" @tab-click="handleTabClick">
                <el-tab-pane v-for="(deal_step, index) in filteredSteps" :label="deal_step.name" :name="'deal_step_' + index">
                    <div class="pt-5 pb-2">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <select-attributes
                                    :size="'large'"
                                    @getItem="getAttribute"
                                    :placeholder="$t('message.attributes')"
                                    :step="activeStep"
                                    :id="newForm.attribute_id"
                                    v-model="newForm.attribute_id"
                                >
                                </select-attributes>
                            </el-col>
                            <el-col :span="6">
                                <el-input :placeholder="$t('message.please_input')" v-model="newForm.value_1"></el-input>
                            </el-col>
                            <el-col :span="6">
                                <el-input :placeholder="$t('message.please_input')" v-model="newForm.value_2"></el-input>
                            </el-col>
                            <el-col :span="6">
                                <div>
                                    <el-button 
                                        @click="addAttrRow()"
                                        class="w-100" 
                                        type="primary" 
                                        :icon="editingIndex >= 0 ? 'el-icon-edit' : 'el-icon-plus'"
                                    >
                                        {{ editingIndex >= 0 ? $t('message.update') : $t('message.add') }}
                                    </el-button>
                                </div>
                            </el-col>
                        </el-row>
                        <hr>
                    </div>
                    <div>
                        <template>
                            <el-table
                            :data="categoryAttributes[activeStep]"
                            style="width: 100%">
                                <el-table-column
                                    prop="name"
                                    :label="$t('message.name')">
                                    <template slot-scope="scope">
                                        {{ scope.row.attribute ? scope.row.attribute.name : ''}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="value_1"
                                    :label="$t('message.n_value', {n: 1})">
                                </el-table-column>
                                <el-table-column
                                    prop="value_2"
                                    :label="$t('message.n_value', {n: 2})">
                                </el-table-column>
                                <el-table-column
                                    :label="$t('message.settings')"
                                    width="180">
                                    <template slot-scope="scope">
                                        
                                        <el-button 
                                            type="primary" 
                                            @click.native.prevent="editAttrRow(scope.$index, scope.row)"
                                            icon="el-icon-edit">
                                        </el-button>

                                        <el-button 
                                            type="danger" 
                                            @click.native.prevent="deleteAttrRow(scope.$index)"
                                            icon="el-icon-delete">
                                        </el-button>

                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import SelectAttributes from "@/components/inventory/select-attributes.vue";

export default {
    mixins: [form, drawer],
    props: {
        category: {
            default: Object
        }
    },
    components: {
        SelectAttributes,
    },
    data() {
        return {
            activeStep: null,
            categoryAttributes: {
                category_id: null,
                2: [],
                3: [],
                4: [],
            },
            activeTabName: 'deal_step_0',
            category_form: {
                id: '',
                attribute_id: '',
                value_1: '',
                value_2: '',
                attribute: '',
            },
            editingIndex: -1,
            newForm: {}
        };
    },
    created() {
       this.emptyNewForm();
    },
    computed: {
        ...mapGetters({
            rules: "attributes/rules",
            model: "attributes/model",
            columns: "attributes/columns",
            steps: "attributes/steps",
            attributes: "categories/attributes",
        }),
        filteredSteps() {
            if(this.category.calculation_type != 3){
                return this.steps.filter(el => el.id != 4);
            }
            return this.steps
        }
    },
    methods: {
        ...mapActions({
            updateList: "categories/getAttributes",
            save: "categories/updateAttributes",
        }),
        afterOpen() {
            this.updateList({category_id: this.category.id})
                .then(res => {
                    if(this.attributes){
                        this.categoryAttributes['2'] = this.attributes['2'] ? JSON.parse(JSON.stringify(this.attributes['2'])) : [];
                        this.categoryAttributes['3'] = this.attributes['3'] ? JSON.parse(JSON.stringify(this.attributes['3'])) : [];
                        this.categoryAttributes['4'] = this.attributes['4'] ? JSON.parse(JSON.stringify(this.attributes['4'])) : [];
                    }
                    this.categoryAttributes.category_id = this.category.id;
                    this.activeTabName = 'deal_step_0';
                    this.activeStep = this.steps.length > 0 ? this.steps[0].id : '';
                })
                .catch(err => {
                    console.log(err, 'errerr');
                });
        },
        getAttribute(attr) {
            let has_item = this.findAttributeInTable(attr.id);
            if(has_item){
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.already_exists')
                });
                this.emptyNewForm();
            }else{
                this.newForm.attribute = {
                    id: attr.id,
                    name: attr.name,
                    step: attr.step,
                };
            }
        },
        findAttributeInTable(id) {
            let attr = '';
            if(this.activeStep >= 0 && this.categoryAttributes[this.activeStep] && Array.isArray(this.categoryAttributes[this.activeStep])){
                attr = this.categoryAttributes[this.activeStep].find(el => el.attribute_id == id);
            }
            return attr;
        },
        handleTabClick(tab, event) {
            this.emptyNewForm();
            let step = this.steps[tab.index];
            this.activeStep = step.id;
        },
        addAttrRow(){
            let has_item = this.newForm.attribute_id ? this.findAttributeInTable(this.newForm.attribute_id) : null;
            if(this.editingIndex < 0 && (has_item || !this.newForm.attribute_id)){
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.already_exists')
                });
                this.emptyNewForm();
            }else{
                let item = JSON.parse(JSON.stringify(this.newForm));
                if(this.activeStep >= 0 && this.categoryAttributes[this.activeStep] && Array.isArray(this.categoryAttributes[this.activeStep])){
                    if(this.editingIndex >= 0) {
                        this.categoryAttributes[this.activeStep][this.editingIndex] = item;
                        this.categoryAttributes[this.activeStep] = JSON.parse(JSON.stringify(this.categoryAttributes[this.activeStep]));
                        this.editingIndex = -1;
                    } else {
                        this.categoryAttributes[this.activeStep].push(item);
                    }
                }
                this.emptyNewForm();
            }
        },
        emptyNewForm() {
            this.newForm = JSON.parse(JSON.stringify(this.category_form));
        },
        editAttrRow(index, row){
            this.newForm = JSON.parse(JSON.stringify(
                {
                    id: row.id,
                    attribute_id: row.attribute_id,
                    value_1: row.value_1,
                    value_2: row.value_2,
                    attribute: row.attribute,
                }
            ));
            this.editingIndex = index;
        },
        deleteAttrRow(index){
            if(this.activeStep >= 0 && this.categoryAttributes[this.activeStep] && Array.isArray(this.categoryAttributes[this.activeStep])){
                this.categoryAttributes[this.activeStep].splice(index, 1);
            }
        },
        submit(close = true) {
            this.loadingButton = true;
            this.save(this.categoryAttributes)
                .then((res) => {
                    this.loadingButton = false;
                    this.$alert(res);
                    this.parent().listChanged();
                    if (close) this.close();
                })
                .catch((err) => {
                    this.loadingButton = false;
                    this.$alert(err);
                });
        },
    },
};
</script>
